<template>
  <div id="app" class="container-fluid" style="padding: 0">
    <!--    <navbar/>-->
    <router-view/>
<!--    <Footer></Footer>-->

  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
html {
  width: 100%;
  height:100%;
  scroll-behavior: smooth;
}
#nav {
  /*padding: 30px;*/
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

</style>
<script>
// import navbar from "@/components/navbar";
// import Footer from "@/components/footer";

export default {
  components: {
    // Footer,
    // navbar,
  },

}
</script>