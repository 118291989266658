// export const domain = "http://127.0.0.1:8000/api/";
export const domain = "https://staging-core.coach.in.th:4000/api";
export const interest_set = parseFloat(0.07);
export const path_img = "https://coany-view.kisrasprint.com/coany/src/assets";
// export const path_img = "http://192.168.2.42:80/coany/src/assets"; //at home



// export const domain_dashboard = "http://127.0.0.1:8000/cases/dashboard";
// export const domain_img_evidence = "http://127.0.0.1:8000";

// export const domain = "http://dip.ddc.moph.go.th/forensic_admin/public/api";


