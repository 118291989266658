import User from "./api/UserAPI";
// import Job from "./api/JobAPI";



export default {
    User,
    // Job

}
