import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueLazyload from 'vue-lazyload'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
require('../src/assets/style.scss');
import Vuex from 'vuex'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store  from './store/index.js'
import VueConfirmDialog from 'vue-confirm-dialog'
import vueConfirmationButton from 'vue-confirmation-button';
// import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import 'vue-fullpage.js/dist/vue-fullpage.js' // Optional. When using fullpage extensions
// import VueFullPage from 'vue-fullpage.js'

// Vue.use(VueFullPage);
Vue.use(VueConfirmDialog)
Vue.use(vueConfirmationButton)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(Vuex)
Vue.use(VuePlyr)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'dist/error.png',
  loading: 'dist/loading.gif',
  attempt: 1
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
