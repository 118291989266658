<template>
  <b-container fluid class="section coach container-fluid" id="coach">
<!--    <div>-->
    <b-row  class="justify-content-center pt-4" >
      <b-col lg="12" md="4" sm="6" cols="12">
          <img src="../assets/icon-coach-search.png">
          <br/>
          <br/>
        <b-card-title><b style="font-size:large;text-wrap: none">สำรวจที่ปรึกษาของคุณ</b></b-card-title>
        <b-card-text>พบปะพูดคุยกับผู้คนหลากหลายที่พร้อมจะแชร์ความรู้ ประสบการณ์และให้คำปรึกษา</b-card-text>
      </b-col>
    </b-row>
    <b-row class="justify-content-center" style="padding-top: 20px">
      <b-col lg="12" sm="12" cols="12" class="Carousel">
        <VueSlickCarousel v-bind="settings" class="VueSlickCarousel">
          <b-row v-for="item in userData" :key="item.id">
            <b-col >
              <b-card
                  overlay
                  :img-src='path_img + item.user_profile[0].path'
                  img-alt="Image"
                  img-top
                  tag="article"
                  class="p-0 card-img-top "
                  id="b-card-coach"
                  @click="show([item])"
              >
              </b-card>
              <b-card-title> <b>{{item.user_name}}</b></b-card-title>
              <b-card-text class="card-text-coach">
                {{item.user_topic}}
              </b-card-text>
            </b-col>
          </b-row>
        </VueSlickCarousel>
      </b-col>
    </b-row>
<!--  </div>-->
    <b-modal  ref="show" size="lg" id="show" hide-header centered scrollable hide-footer >
      <b-container style="padding-right: 0;padding-left: 0;padding-bottom: 15px" >
        <b-row>
          <b-col class="p-0 img-model" cols="12" offset-md="3" md="6" offset-lg="4" lg="4">
            <VueSlickCarousel v-bind="settings2" class="VueSlickCarousel">
              <b-row  v-for="(item, index) in user.user_profile" :key="index-1">
                <b-col class="p-0">
                  <b-card
                      overlay
                      :img-src='path_img +item.path'
                      img-alt="Image"
                      tag="article"
                      class="img-coach"
                      style="height: auto;width: 100%;padding: 0;margin: 0"
                  >
                  </b-card>
                </b-col>
              </b-row>
            </VueSlickCarousel>
          </b-col>
              <div class="btnCloseSm">
                <b-button variant="outline-white" class="rounded-circle px-2" @click="$bvModal.hide('show')">
                  <b-icon icon="x" scale="2" style="color: #FFFFFF"></b-icon>
                </b-button>
              </div>
          <div class="btnCloselg" style="padding-top: 12px">
            <b-button  class="rounded-circle px-2" style="background-color: #e0dada;border: none" @click="$bvModal.hide('show')">
              <b-icon icon="x" scale="1"  style="color: #8c8888"></b-icon>
            </b-button>
          </div>
        </b-row>
        <b-row style="padding-top: 8%">
          <b-col>
            <b-card-title style="text-align: center">{{user.user_name}}</b-card-title>
          </b-col>
        </b-row>
        <b-row  style="padding-top: 3%">
          <b-col class="p-0" cols="12" offset-md="3" md="6" offset-lg="3" lg="6">
            <b-card-text style="text-align: center;">
              <b-row class="text-center" style="margin: 0 5%">
                <b-col lg="4" sm="4" md="5" cols="4">
                  <b  v-if="user.status == 1" style="color: #42b983"><i class="fa fa-circle" aria-hidden="true"></i><br/>ออนไลน์</b>
                  <b v-else style="color: #fc0e0e"><i class="fa fa-circle" aria-hidden="true"></i> <br/>ออฟไลน์</b>
                </b-col>
                <b-col lg="4" sm="4" md="2" cols="4">
                  <p>{{ user.live }}<br/>ไลฟ์</p>

                </b-col>
                <b-col lg="4" sm="4" cols="4" md="5" style="text-wrap: none">
                  <p>{{ user.followers }}<br/>ผู้ติดตาม</p>
                </b-col>
              </b-row>
              <b-row style="padding-top: 5%">
                <b-col cols="12" sm="12" lg="12" md="12">
                  <p><b>เกี่ยวกับ</b></p>
                </b-col>
                <b-col cols="11" sm="11" lg="12"  md="11" >
                  <b-card-text style="text-align: center;color: #2c3e50;font-size: large">{{user.bio}}</b-card-text>
                </b-col>
              </b-row>
            </b-card-text>
          </b-col>

        </b-row>
        <b-row class="px-4" style="padding-top: 25px">
          <b-col class="p-2" sm="4" cols="4" v-for="(item, index) in user.bio_picture" :key="index" >
            <img :src='path_img +item.path' class="img-coach gallery-image"  @click="showImg(path_img +item.path)" style="height: auto;width: 100%;padding: 0" >
          </b-col>
        </b-row>
        <hr class="line">
        <b-modal ref="showImg" size="md" id="showImg"  style="padding-bottom: 0 !important" hide-header centered scrollable hide-footer >
          <img :src='showImg_item' class="img-coach gallery-image" style="height: auto;width: 100%;padding: 0;margin: 0" />
            <button class="rounded-circle px-1 "  style=" position: absolute;top: 0;right: 0;background-color: transparent;border: none" @click="$bvModal.hide('showImg')">
              <b-icon icon="x" scale="2" style="color: #585252"></b-icon>
            </button>
        </b-modal>
        <b-row>
          <b-col class="p-0" cols="12" offset-md="3" md="6" offset-lg="4" lg="4">
            <b-card-text style="text-align: left">
              <b-row>
                <b class="text-modal">หมวดหมู่ :</b>
                <p class="text-modal"> {{user.category}}</p>
              </b-row>
            </b-card-text>
          </b-col>
        </b-row>
        <b-row>
          <hr class="line">
          <b-col class="p-0" cols="12" offset-md="3" md="6" offset-lg="4" lg="4">
            <b-card-text style="text-align: left">
              <b-row>
                <b class="text-modal">หัวข้อโค้ช :</b>
                <p class="text-modal"> {{user.user_topic}}</p>
              </b-row>
            </b-card-text>
          </b-col>
        </b-row>
        <b-row v-if="user.expertise && user.expertise != null || user.expertise != ''">
          <hr class="line">
          <b-col class="p-0" cols="12" offset-md="3" md="6" offset-lg="4" lg="4">
            <b-card-text style="text-align: left">
              <b-row>
                <b class="text-modal">ความเชี่ยวชาญเฉพาะทาง :</b>
                <p class="text-modal"> {{user.expertise}}</p>
              </b-row>
            </b-card-text>
          </b-col>
        </b-row>
        <b-row v-if="user.work_history && user.work_history != null || user.work_history != ''">
          <hr class="line">
          <b-col class="p-0" cols="12" offset-md="3" md="6" offset-lg="4" lg="4">
            <b-card-text style="text-align: left">
              <b-row>
                <b class="text-modal">ประวัติการทำงาน :</b>
                <p class="text-modal"> {{user.work_history}}</p>
              </b-row>
            </b-card-text>
          </b-col>
        </b-row>
        <b-row  v-if="user.other && user.other != null || user.other != ''">
          <hr class="line">
          <b-col class="p-0" cols="12" offset-md="3" md="6" offset-lg="4" lg="4">
            <b-card-text style="text-align: left">
              <b-row>
                <b class="text-modal">อื่นๆ :</b>
                <p class="text-modal"> {{user.other}}</p>
              </b-row>
            </b-card-text>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>
<style>
@media only screen and (min-width: 767px) {
  .coach{
    padding-top: 15%;
  }
  .btnCloseSm{
    display: none;
  }
  .text-modal{
    color: black;
    padding-left: 5% !important;
  }
  .btnCloselg{
    position: absolute;
    top: 0;
    right: 10px;
  }
  .img-coach{
   margin-top: 10%;
  }
}
@media only screen and (max-width: 767px) {
  .btnCloselg{
    display: none;
  }
  .btnCloseSm{
    position: absolute;
    top: 0;
    right: 0;
  }
}
.coach{

  /*height: 100vh;*/
  justify-content: center;
  text-align: center;
}
.slick-next:before,.slick-prev:before{
  color: black;
  padding-top: 50%;
}
.text-modal{
  color: black;
  padding-left: 5%;
  margin-left: 5%

}
.line{
  border: 1px solid #dde1e3;
  width: 98%
}
.modal-body{
  padding: 0 !important;
  /*padding-bottom: 2% !important;*/
  overflow-x: hidden;
}

/*#modal-header{*/
/*  background-color: rgba(0,0,0,.0001) !important;*/
/*  overflow-x: hidden;*/
/*}*/
.show{
  text-align: center;
  justify-content: center;
}
/*.modal-custom{*/
/*  background-color: #D70153;*/
/*  border: #D70153 1px;*/
/*}*/
.card-img-top{
  height: 50%;
  width: auto;
  border-radius:20px!important;
}
/*.card-title-coach{*/
/*  position: absolute;*/
/*  left: 4%;*/
/*  top: 90%;*/
/*  color: white;*/
/*  z-index: 1;*/
/*  border: #D70153;*/
/*  font-size: 20px;*/
/*}*/
.card-text-coach{
  top: -100px;
  font-size: 20px;
}
/*.bg{*/
/*  position: absolute;*/
/*  left: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  height: 100%;*/
/*  border-radius: 20px !important;*/
/*  background: rgb(0,0,0);*/
/*  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.2) 25%, rgba(0,0,0,0) 100%);*/
/*}*/

</style>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapState} from 'vuex'
import {path_img} from '@/util/Constants'

export default {
  name: 'Coach',
  computed:{
    ...mapState({
      userData:state => {
        return state.apiData.userData
      }
    }),
  },
  data(){
    return{
      slide: 0,
      sliding: null,
      path_img:path_img,
      settings:{
        "draggable":true,
        "focusOnSelect": true,
        "arrows": false,
        "dots": false,
        "infinite": true,
        "initialSlide": 2,
        "slidesToShow": 4,
        // "slidesToScroll": 1,
        "swipeToSlide": true,
        "autoplay": true,
        "ltr": true,
        "speed": 1000,
        "autoplaySpeed": 5000,
        "cssEase": "linear",
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": false,
              // "arrows": false,

            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2.95,
              // "initialSlide": 2,
              "swipeToSlide": true,
              // "arrows": true,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "centerMode": true,
              "slidesToShow": 1,
              "slidesToScroll":1.95,
              "swipeToSlide": true,
              // "arrows": true,
            }
          },
          {
            "breakpoint": 375,
            "settings": {
              "centerMode": true,
              "slidesToShow":1,
              "slidesToScroll":1.25,
              "swipeToSlide": true,
              // "arrows": true,
            }
          }
        ]
      },
      settings2:{
        "focusOnSelect": true,
        "arrows": true,
        "dots": true,
        "infinite": false,
        "initialSlide": 1,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "swipeToSlide": true,
        "autoplay": false,
        "ltr": true,
        "draggable":true,
        // "speed": 2000,
        "autoplaySpeed": 2500,
        // "cssEase": "linear",
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": false,
              "dots": true,
              "arrows": true,

            }
          },
          {
            "breakpoint": 600,
            "settings": {
              // "centerMode": true,
              "slidesToShow":1,
              // "slidesToScroll":1,
              "swipeToSlide": false,
              "arrows": true,
              "dots": true,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              // "centerMode": true,
              "slidesToShow":1,
              // "slidesToScroll":1,
              "swipeToSlide": false,
              "arrows": true,
              "dots": true,
            }
          },
          {
            "breakpoint": 375,
            "settings": {
              // "centerMode": true,
              "slidesToShow":1,
              // "slidesToScroll":1,
              "swipeToSlide": false,
              "arrows": true,
              "dots": true,
            }
          }
        ]
      },
      user:{
        id:null,
        user_name:null,
        user_topic:null,
        user_profile:[],
        status:null,
        live:null ,
        followers:null,
        bio:null,
        bio_picture:[],
        category:null,
        expertise:null,
        work_history:null,
        other:null
      },
      showImg_item:null,
      // modalId:  "modal-user-data"
    }
  },
  components: {
    VueSlickCarousel,
  },

  methods: {
    show(item){
      console.log(item[0]['user_profile'])
      this.user={
        id:item[0]['id'],
        user_name:item[0]['user_name'],
        user_topic:item[0]['user_topic'],
        user_profile:item[0]['user_profile'],
        status:item[0]['status'],
        live:item[0]['live'],
        followers:item[0]['followers'],
        bio:item[0]['bio'],
        bio_picture:item[0]['bio_picture'],
        category:item[0]['category'],
        expertise:item[0]['expertise'],
        work_history:item[0]['work_history'],
        other:item[0]['other']
      }
      // var data = "sss"
      // item = null;
     console.log("data",this.user)
      // this.$store.dispatch("apiData/onSetUser",data)
      this.$refs['show'].show(this.user)
    },
    showImg(item){
      // console.log("item",item)
      this.showImg_item = item
      this.$refs['showImg'].show(this.showImg_item)
    }
  }
}
</script>
