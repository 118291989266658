import Service from "../service"


const User = {
    onGetUser(){
        return Service.get('/user')
    },
    // onUploadPeopleCard(data){
    //     return Service.post('/uploadCitizenImage/'+data.id,data)
    // },
    // onUploadFile(data){
    //     return Service.post('/uploadTranscript/'+data.id,data)
    // },
    // onSave(data){
    //     return Service.post('/register/'+data.id,data)
    // },
    // onCheckOTP(data){
    //     return Service.post('/checkOTP/'+data.id,data)
    // },

}


export default User;