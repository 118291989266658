import Service from "../../service/index";


const apiData={
    name: 'apiData',
    stateFactory: true,
    namespaced: true,
    state: {
        userData:[]
        // userData:{
        //     id: null,
        //     user_name: null,
        //     user_topic: null,
        //     user_profile:[],
        //     status: null,
        //     live: null,
        //     followers: null,
        //     bio:null,
        //     bio_picture: [],
        //     category: null,
        //     expertise: null,
        //     work_history: null,
        //     other:null
        // },


    },
    mutations : {
        GET_USER: (state, data) => {
            // state.userData = data
            state.userData = data
            // state.userData[
            //         state.userData = data.id,
            //         state.userData.user_name= data.user_name,
            //         state.userData.user_topic= data.user_topic,
            //         state.userData.user_profile=JSON.parse(data.user_profile),
            //         state.userData.status= data.status,
            //         state.userData.live= data.live,
            //         state.userData.followers= data.followers,
            //         state.userData.bio=data.bio,
            //         state.userData.bio_picture= JSON.parse(data.bio_picture),
            //         state.userData.category= data.category,
            //         state.userData.expertise= data.expertise,
            //         state.userData.work_history= data.work_history,
            //         state.userData.other=data.other
            // ]
            // state.userData={
            //             id :data.id,
            //             user_name: data.user_name,
            //             user_topic: data.user_topic,
            //             user_profile:JSON.parse(data.user_profile),
            //             status: data.status,
            //             live: data.live,
            //             followers: data.followers,
            //             bio:data.bio,
            //             bio_picture: JSON.parse(data.bio_picture),
            //             category: data.category,
            //             expertise: data.expertise,
            //             work_history: data.work_history,
            //             other:data.other
            // }
            console.log("state.userData",state.userData)

        },
        SET_USER: (state, data) => {
            state.userData = data

        },
        // SET_MONEYLIMIT: (state, data) => {
        //     state.form = {
        //         moneyPerInstallment:data.moneyPerInstallment,   //ยอดต่องวด
        //         totalMoney:data.totalMoney,            //ยอดทั้งหมด
        //         interestInstallment:data.interestInstallment,   //ดอกเบี้ยทั้งหมด
        //         selectedMoney: data.selectedMoney,        //จำนวนเงิน
        //         selectedMonth: data.selectedMonth,        //จำนวนงวดที่ผ่อน
        //         job:null,                   //อาชีพ
        //         job_id : null,
        //         citizen_image:null,     //รูปบัตรประชาชน
        //         transcript:null,                  //ใบรับรองเงินเดือน/ทรานสคริป
        //         income:null,
        //     }
        // },
        // SET_MONEY: (state, data) => {
        //     state.form.selectedMoney = data
        // },
        // SET_MONTH: (state, data) => {
        //     state.form.selectedMonth = data
        // },
        // SET_JOB: (state, data) => {
        //     state.form.job = data
        // },
        // SET_PEOPLE_CARD: (state, data) => {
        //     state.form.citizen_image = data
        //     console.log("SET IMAGE : "+state.form.citizen_image);
        // },
        // SET_FILES: (state, data) => {
        //     state.form.transcript = data
        //     console.log("SET FILE : "+state.form.transcript);
        // },
        // GET_JOB: (state, data) => {
        //     state.joblist = data
        // },
        // SET_FILE_TYPE: (state, data) => {
        //     state.temp.typeFile = data
        // },
        // SET_VERIFY: (state, data) => {
        //     state.temp.refs = data.refs
        //     state.temp.otp = data.otp
        //
        // },
        // CLEAR_FORM_DATA: state => {
        //     state.form={
        //             moneyPerInstallment:null,   //ยอดต่องวด
        //             totalMoney:null,            //ยอดทั้งหมด
        //             interestInstallment:null,   //ดอกเบี้ยทั้งหมด
        //             selectedMoney: null,        //จำนวนเงิน
        //             selectedMonth: null,        //จำนวนงวดที่ผ่อน
        //             job:null,                   //อาชีพ
        //             job_id : null,
        //             citizen_image:null,     //รูปบัตรประชาชน
        //             transcript:null,                  //ใบรับรองเงินเดือน/ทรานสคริป
        //             income:null,                //รายได้
        //     }
        //         state.temp={
        //         typeFile:null,
        //             refs:null,
        //
        //     }
        // },

    },
    actions:{
        onGetUser({commit}) {
            return Service.User.onGetUser().then(res=>{

                console.log("GET_USER",res)
                commit("GET_USER", res);
                return res
            })

        },
        onSetUser({commit},data) {
                console.log("SET_USER",data)
                commit("SET_USER", data);
        },
        // clearFormData({ commit }) {
        //    return  commit("CLEAR_FORM_DATA");
        // },
        // // eslint-disable-next-line no-unused-vars
        // onGetJob({commit}){
        //     return Service.Job.onGetJob();
        // },
        // onSelectMoneyLimit({commit}, data) {
        //     console.log("SET_MONEYLIMIT", data)
        //     commit("SET_MONEYLIMIT", data);
        //     return data
        // },
        // onSelectMoney({commit}, data) {
        //     console.log("SET_MONEY", data)
        //     commit("SET_MONEY", data);
        //     return data
        // },
        // onSelectMonth({commit}, data) {
        //     console.log("SET_MONTH", data)
        //     commit("SET_MONTH", data);
        //     return data
        // },
        // onSelectJob({commit}, data) {
        //     console.log("SET_JOB", data)
        //     commit("SET_JOB", data);
        //     return data
        // },
        // // eslint-disable-next-line no-unused-vars
        // onUploadPeopleCard({commit}, data) {
        //     return Service.User.onUploadPeopleCard(data).then(res=>{
        //         commit("SET_PEOPLE_CARD", res);
        //     })
        //
        // },
        // onUploadFile({commit}, data) {
        //     return Service.User.onUploadFile(data).then(res=>{
        //         commit("SET_FILES", res);
        //     })
        // },
        // onSetFileType({commit}, data) {
        //     console.log("SET_FILE_TYPE", data)
        //         commit("SET_FILE_TYPE", data);
        //         return data
        // },
        // // eslint-disable-next-line no-unused-vars
        // onSave({commit},data){
        //     console.log("save", data)
        //     return Service.User.onSave(data).then(res=>{
        //         console.log("verify",res)
        //         commit("SET_VERIFY", res);
        //         return res
        //     })
        // },
        // eslint-disable-next-line no-unused-vars
        // onCheckOTP({commit},data){
        //     console.log("toAPI","data.id", data.id," data.refs", data.refs,"data.otp",data.otp);
        //     return Service.User.onCheckOTP(data).then(res=>{
        //         console.log("verify",res)
        //         return res
        //     })
        // },

    }


}

export default apiData;