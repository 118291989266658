import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import apiData from './modules/apiDataModule'

Vue.use(Vuex);

const modules = {
    apiData:apiData
}



export default new Vuex.Store({
    modules,
    plugins: [createPersistedState()]
});
