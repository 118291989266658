<template>
  <div id="view_home">
<!--    <navbar/>-->
<!--    <nav_section/>-->
<!--    <full-page  :options="options" id="">-->
<!--      <div class="section">-->
<!--        First section ...-->
<!--      </div>-->
<!--      <div class="section">-->
<!--        Second section ...-->
<!--      </div>-->
<!--      <div class="section" id="home" >-->
<!--      <HomePage></HomePage>-->
<!--      </div>-->
<!--      <div  class="section" id="about" >-->
<!--        <About></About>-->
<!--      </div>-->
      <div  class="section" id="coach">
        <Coach></Coach>
      </div>
<!--      <div  class="section" id="HowtoCallCoach">-->
<!--        <HowtoCallCoach></HowtoCallCoach>-->
<!--      </div>-->
<!--      <div class="section" id="HowtobeCoach">-->
<!--        <HowtobeCoach></HowtobeCoach>-->
<!--      </div>-->

<!--      <div class="section" id="Payment">-->
<!--        <Payment></Payment>-->
<!--      </div>-->
<!--      <div class="section" id="Lastpage">-->
<!--        <Lastpage></Lastpage>-->
<!--&lt;!&ndash;        <Footer/>&ndash;&gt;-->
<!--      </div>-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--    </full-page>-->




  </div>

</template>

<script>
// @ is an alias to /src
// import navbar from '@/components/navbar.vue'
// import HomePage from '@/components/Homepage.vue'
// import About from '@/components/About.vue'
import Coach from '@/components/Coach.vue'
// import HowtoCallCoach from '@/components/HowtoCallCoach.vue'
// import HowtobeCoach from '@/components/HowtobeCoach.vue'
// import Payment from '@/components/Payment.vue'
// import Lastpage from '@/components/Lastpage.vue'
// import nav_section from '@/components/nav_section.vue'

// import Footer from "@/components/footer";
// import Footer from '@/components/footer.vue'


export default {
  created() {
    this.$store.dispatch("apiData/onGetUser")
  },
  name: 'CoachLists',
  // data: function(){
  //   return {
  //     options: {
  //       afterLoad: this.afterLoad,
  //       // navigation: true,
  //       // anchors: ['home', 'about', 'aoach','howtoCallCoach','howtobeCoach','payment','lastpage'],
  //       // sectionsColor: ['#41b883', '#ff5f45', '#0798ec', '#fec401', '#1bcee6', '#ee1a59', '#2c3e4f', '#ba5be9', '#b4b8ab']
  //     },
  //   }
  // },
  // methods: {
  //   // afterLoad: function(origin, destination, direction){
  //   //   console.log("After load....");
  //   //   console.log(destination);
  //   // },
  //   addSection: function(e) {
  //     var newSectionNumber = document.querySelectorAll('.fp-section').length + 1
  //     console.log(e)
  //     // creating the section div
  //     var section = document.createElement('div')
  //     section.className = 'section'
  //     section.innerHTML = `<h3>Section ${newSectionNumber}</h3>`
  //
  //     // adding section
  //     document.querySelector('#fullpage').appendChild(section)
  //
  //     // creating the section menu element
  //     var sectionMenuItem = document.createElement('li')
  //     sectionMenuItem.setAttribute('data-menuanchor', 'page' + newSectionNumber)
  //     sectionMenuItem.innerHTML = `<a href="#page${newSectionNumber}">Section${newSectionNumber}</a>`
  //
  //     // adding anchor for the section
  //     this.options.anchors.push(`page${newSectionNumber}`)
  //
  //     // we have to call `update` manually as DOM changes won't fire updates
  //     // requires the use of the attribute ref="fullpage" on the
  //     // component element, in this case, <full-page>
  //     // ideally, use an ID element for that element too
  //     this.$refs.fullpage.build()
  //   },
  //
  //   removeSection: function(){
  //     var sections = document.querySelector('#fullpage').querySelectorAll('.fp-section')
  //     var lastSection = sections[sections.length - 1]
  //
  //     // removing the last section
  //     lastSection.parentNode.removeChild(lastSection)
  //
  //     // removing the last anchor
  //     this.options.anchors.pop()
  //
  //     // removing the last item on the sections menu
  //     var sectionsMenuItems = document.querySelectorAll('#menu li')
  //     var lastItem = sectionsMenuItems[sectionsMenuItems.length - 1]
  //     lastItem.parentNode.removeChild(lastItem)
  //   },
  // },
  components: {
    // Footer,
    // navbar,
    // HomePage,
    // About,
    Coach,
    // HowtoCallCoach,
    // HowtobeCoach,
    // Payment,
    // Lastpage,
    // nav_section,
    // Footer,

  }
}
</script>
<style>

img{
  padding: 0;
}
*{
  padding: 0;
}
</style>
